<template>
  <div class="general-padding" id="order-detail">
    <div v-if="openOrder">
      <h3 v-if="order">
        <a>
          <font-awesome-icon icon="chevron-left" @click="goToOrdersPage()"/>
        </a> Regresar a los pedidos
      </h3>
      <h3 v-else-if="checkout">
        <a>
          <font-awesome-icon icon="chevron-left" @click="goCheckoutsPage()"/>
        </a> Regresar a los pedidos en espera
      </h3>
      <b-card>
        <b-row>
          <b-col cols="12" md="6">
            <h2>Numero de pedido: {{openOrder?.order_number}}</h2>
          </b-col>
          <b-col cols="12" md="6">
            <b-row class="justify-content-end my-1">
                <b-col md="3">
                  <b-btn v-if="checkout"
                    variant="primary"
                    size="sm"
                    class="w-100"
                    :disabled="!(permissionGranted && ['CREATED','PENDING-PAYMENT'].includes(openOrder?.overall_status))"
                    @click="showCommercialEmail = true"
                  >Mail carrito <br> olvidado</b-btn>
                  <b-btn
                    v-if="order"
                    variant="danger"
                    size="sm"
                    class="w-100"
                    :disabled="!(permissionGranted && openOrder?.overall_status == 'RECEIVED')"
                    @click="showCancelOrder = true"
                  >Cancelar</b-btn>
                  <b-btn v-if="checkout"
                    variant="danger"
                    size="sm"
                    class="w-100"
                    :disabled="!permissionGranted"
                    @click="showDeleteCheckout = true"
                  >Eliminar</b-btn>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                  <b-btn
                      :variant="getVariantFromStatus('CREATED') + getVariantFromStatus('PENDING-PAYMENT')"
                      size="sm"
                      class="w-100"
                      :disabled="getDisabledFromStatus('CREATED') && getDisabledFromStatus('PENDING-PAYMENT')"
                      @click="showReceivePayment = true"
                    >Validar Pago</b-btn>
                </b-col>
                <b-col md="3">
                  <b-btn
                      :variant="getVariantFromStatus('RECEIVED')"
                      size="sm"
                      class="w-100"
                      :disabled="getDisabledFromStatus('RECEIVED')"
                      @click="showConfirmation = true"
                    >Confirmar</b-btn>
                </b-col>
                <b-col md="3">
                  <b-btn
                      :variant="getVariantFromStatus('CONFIRMED')"
                      size="sm"
                      class="w-100"
                      :disabled="getDisabledFromStatus('CONFIRMED')"
                      @click="openShippingModal"
                    >Enviar</b-btn>
                </b-col>
                <b-col md="3">
                  <b-btn
                      :variant="getVariantFromStatus('IN-PROGRESS')"
                      size="sm"
                      class="w-100"
                      :disabled="getDisabledFromStatus('IN-PROGRESS')"
                      @click="showComplete = true"
                    >Completar</b-btn>
                </b-col>
              </b-row>
          </b-col>

        </b-row>
        <p class="status">
          <b-row>
            <b-col md="2">
              Estado : {{ openOrder?.overall_status }}
            </b-col>
            <b-col md="10">
              <b-row>
                <b-col md="12">
                  <b-progress class="mt-2" :max="100" show-value>
                    <b-progress-bar v-for="(status,index) in orderStatus"
                      :key="index"
                      :value="((1/orderStatus.length)*100)"
                      :variant="(openOrder?.overall_status == status) ? 'primary' : 'dark'">
                      {{translationStatus(status)}}
                    </b-progress-bar>
                  </b-progress>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </p>

        <b-row>
          <b-col>
            <div>
              <h5>Direccion</h5>
              <hr>
              {{addr.firstName}} {{addr.lastName}} <br>
              {{addr.addressLine1}} <br>
              <span v-if="addr.addressLine2">{{addr.addressLine2}}<br></span>
              {{addr.city}} {{addr.state}} <br>
              {{addr.country}} {{addr.zipCode}}
            </div>
            <b-alert show variant="warning">
              <h6><strong>Condiciones de transporte C807 Express</strong></h6>
              <span v-if="openOrder?.cart?.shippingConditions?.risk" style="text-decoration: underline;">
                Zona de riesgo por {{ new String(openOrder?.cart?.shippingConditions?.risk).toLowerCase() }} <br />
              </span>
              <span>Entregas : {{ openOrder?.cart?.shippingConditions?.deliverySchedule }}</span>
              <br />
              <span v-if="openOrder?.cart?.shippingConditions?.deliveryInstruction">
                Modalidad : {{ openOrder?.cart?.shippingConditions?.deliveryInstruction }}
              </span>
              <span><strong>Costo por paquete : ${{ openOrder?.cart?.shippingConditions?.rate }}</strong></span>
            </b-alert>
          </b-col>
          <b-col>
            <h5>Resumen</h5>
            <hr>
            <div>
              N. de Paquetes : {{ openOrder?.cart.shippingPackagesTotal }} <br><br>

              Sub total : ${{ openOrder?.cart.subTotalPrice?.amount }} <br>
              Envio : ${{ openOrder?.cart.shippingPrice?.amount }}<br>
              Impuesto : ${{ openOrder?.cart.taxPrice?.amount }}<br>
              {{ openOrder.cart.coupon?.code || '' }} : -${{ openOrder.cart.discount?.amount || 0 }}
              <h6><strong>TOTAL : ${{ openOrder?.cart.totalPrice?.amount }}</strong></h6><br>
            </div>
          </b-col>
          <b-col>
            <h5>Fechas</h5>
            <hr>
            <ul class="order-desc">
              <li>
                <strong>Creada por: </strong>
                &nbsp;&nbsp;&nbsp;{{openOrder?.auditLog.createdBy.name}} / {{openOrder?.auditLog.createdBy.email}}
              </li>
              <li>
                <strong>Fecha de creacion:</strong>
                &nbsp;&nbsp;&nbsp;{{openOrder?.auditLog.createdOn | filterDate}}
              </li>
            </ul>

            <ul class="order-desc">
              <li>
                <strong>Actualizada por :</strong>
                &nbsp;&nbsp;&nbsp;{{openOrder?.auditLog.updatedBy.name}} / {{openOrder?.auditLog.updatedBy.email}}
              </li>
              <li>
                <strong>Fecha actualizacion:</strong>
                &nbsp;&nbsp;&nbsp;{{openOrder?.auditLog.updatedOn | filterDate}}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>

        <b-card>
        <h5>Detalle de los productos</h5>
        <hr>

        <b-table striped hover :items="openOrder?.cart.items" :fields="cartTableFields">
          <template #cell(product_id)="data">
            <a :href="`${shopProductUrl}${data.item.product._id}`" target="_blank" style="color: blue">{{data.item.product.code_inc}}</a>
          </template>
          <template #cell(product_name)="data">
            {{data.item.product.name}}
          </template>
          <template #cell(product_brand)="data">
            {{data.item.product.brand.name}}
          </template>
          <template #cell(product_options)="data">
            <span v-for="(value, name)  in data.item?.customizations">
              {{name}} : {{value}}
            </span>
          </template>
          <template #cell(product_price)="data">
            ${{data.item.product.price?.amount}}
          </template>
          <template #cell(shipping_ratio_percentage)="data">
            {{data.item.product.shipping_ratio_percentage}}
          </template>
          <template #cell(aggregatedPrice)="data">
            ${{data.item.aggregatedPrice?.amount}}
          </template>
        </b-table>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <h5>Detalles de pago</h5>
            <hr>
            <b-table striped hover :items="payments" :fields="paymentTableFields">
              <template #cell(date)="data">
                {{ data.item.date | filterDate}}
              </template>
              <template #cell(amount_in_usd)="data">
                $ {{ data.item.amount_in_usd?.amount }}
              </template>
              <template #cell(payment_service_response_dump)="data">
                <b-table stacked :items="[data.item.payment_service_response_dump]"></b-table>
              </template>
            </b-table>
          </b-col>
        </b-row>

      </b-card>
      <comments-section/>
    </div>

    <confirmation-page v-if="showCommercialEmail"
      title="Enviar un mail comercial al cliente"
      text="El cliente recibira un email con la informacion del carrito de compras y un enlace para acceder a el"
      okTitle="Enviar mail"
      cancelTitle="Regresar"
      @yes="sendCommercialEmail"
      @no="showCommercialEmail = false"/>
    <confirmation-page v-if="showDeleteCheckout"
      title="Borrar el carrito de compras"
      text="El borrador de orden sera eliminado definitivamente. Esta accion no es reversible."
      okTitle="Borrar definitivamente"
      cancelTitle="Regresar"
      @yes="deleteCheckout"
      @no="showDeleteCheckout = false"/>
    <confirmation-page v-if="showReceivePayment"
      title="Validar el pago y recibir la orden"
      text="La orden sera marcada como pagada y el cliente recibira un mail de confirmacion."
      okTitle="Recibir orden"
      cancelTitle="Regresar"
      @yes="receiveOrder"
      @no="showReceivePayment = false"
      :formModel="paymentValidationObject"/>
    <confirmation-page v-if="showConfirmation"
      title="Confirmar la orden y comenzar su preparacion"
      text="El cliente recibira un mail de confirmacion."
      okTitle="Preparar orden"
      cancelTitle="Regresar"
      @yes="updateOrderStatus('CONFIRMED')"
      @no="showConfirmation = false"/>
    <confirmation-page v-if="showShipping"
      title="Enviar la orden"
      text="El cliente recibira un mail de confirmacion con la informacion de envio."
      okTitle="Enviar orden"
      cancelTitle="Regresar"
      @yes="shipOrder"
      @no="showShipping = false"
      :formModelWithSpec="shippingDataObject"
      :formModel="null"/>
    <confirmation-page v-if="showComplete"
      title="Completar la orden"
      text="La orden ha sido recibida por el cliente. El cliente recibira un mail."
      okTitle="Completada orden"
      cancelTitle="Regresar"
      @yes="completeOrder"
      @no="showComplete = false"
      :formModelWithSpec="deliveryDataObject"
      :formModel="null"/>
    <confirmation-page
      v-if="showCancelOrder"
      text="Estas seguro que quieres cancelar la orden ? El cliente recibira un mail de confirmacion. El reembolzo tiene que hacerce manualmente con WompiSV."
      okTitle="Cancelar orden"
      cancelTitle="Regresar"
      @yes="updateOrderStatus('CANCELLED')"
      @no="showCancelOrder = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SingleListItem from '@/components/orders/SingleListItem.vue';
import ConfirmationPage from '@/components/common/ConfirmationPage.vue';
import CommentsSection from '@/components/orders/CommentsSection.vue';
import Permission from '@/constants/permissions';
import moment from 'moment';

export default {
  name: 'OrderDetail',
  components: {
    SingleListItem,
    ConfirmationPage,
    CommentsSection,
  },
  data() {
    return {
      showDeleteCheckout: false,
      showCancelOrder: false,
      showReceivePayment: false,
      showConfirmation: false,
      showShipping: false,
      showComplete: false,
      openOrder: null,
      showCommercialEmail: false,
      orderStatus : [
          "CREATED",
          "PENDING-PAYMENT",
          "RECEIVED",
          "CONFIRMED",
          "IN-PROGRESS",
          "COMPLETED",
          "CANCELLED"
      ],
      paymentTableFields: [
          {
            key: 'date',
            label: 'Fecha',
            sortable: true
          },
          {
            key: 'source',
            label: 'Origen',
          },
          {
            key: 'payment_id',
            label: 'Id pago',
          },
          {
            key: 'transaction_id',
            label: 'Id pedido',
          },
          {
            key: 'amount_in_usd',
            label: 'Valor',
          },
          {
            key: 'payment_service_response_dump',
            label: 'Detalle',
          }
        ],

      cartTableFields: [
          {
            key: 'product_id',
            label: 'Codigo',
            sortable: true
          },
          {
            key: 'product_name',
            label: 'Nombre',
            sortable: true
          },
          {
            key: 'product_brand',
            label: 'Marca',
            sortable: true
          },
          {
            key: 'product_options',
            label: 'Opciones',
            sortable: true
          },
          {
            key: 'shipping_ratio_percentage',
            label: 'Capacidad paquete',
            sortable: true
          },
          {
            key: 'product_price',
            label: 'Precio unidatio',
            sortable: true
          },
          {
            key: 'counts',
            label: 'Cantidad',
          },
          {
            key: 'aggregatedPrice',
            label: 'Valor Total',
          }
        ],
      paymentValidationObject:{
        identificadorEnlaceComercio: '',
        idTransaccion: '',
        idEnlace: ''
      },
      shippingDataObject: {
        provider: {
          label: 'Transportista',
          type: 'text',
          value: 'C807Express'
        },
        trackingNumber: {
          label: 'Numero de guia',
          type: 'text',
          value: ''
        },
        trackingUrl: {
          label: 'Url de seguimiento de la guia',
          type: 'text',
          value: 'https://c807xpress.com/sv/'
        },
        service: {
          label: 'Servicio',
          type: 'text',
          value: 'Servicio Entrega Regular (SER)'
        },
        paidPostageInUSD: {
          label: 'Costo de envio en USD',
          type: 'number',
          value: 0
        }
      },
      deliveryDataObject: {
        deliveryDate: {
          label: 'Fecha de entrega',
          type: 'date',
          value: moment().format('YYYY-MM-DD')
        }
      }
    };
  },

  created() {
    this.openOrder = this.order || this.checkout
    if (this.openOrder == null) {
      this.$router.push({ path: '/orders' });
    }
    this.paymentValidationObject.identificadorEnlaceComercio= this.openOrder?._id
  },

  filters: {
    filterDate(dd) {
      return moment(dd).format('DD/MM/YYYY h:mm:ss a');
    }
  },

  methods: {
    openShippingModal() {
      this.shippingDataObject.paidPostageInUSD.value = this.openOrder?.cart?.shippingConditions?.rate;
      this.showShipping = true
    },
    getDisabledFromStatus(status) {
      return this.openOrder?.overall_status === status && this.permissionGranted ? false : true;
    },
    getVariantFromStatus(status) {
      return this.openOrder?.overall_status === status && this.permissionGranted ?'success':'';
    },
    translationPaymentStatus(status){
      switch (status) {
        case 'PENDING':
          return 'Carritos pendientes'
        case 'SALE':
          return 'Venta realizada'
        case 'FAILED':
          return 'Rechazada'
        default:
          return '?'
      }
    },
    translationStatus(status){
      switch (status) {
        case 'CREATED':
          return 'Creada'
        case 'PENDING-PAYMENT':
          return 'Espera de pago'
        case 'RECEIVED':
          return 'Recibida'
        case 'CONFIRMED':
          return 'En preparación'
        case 'IN-PROGRESS':
          return 'Enviada'
        case 'COMPLETED':
          return 'Completada'
        case 'CANCELLED':
          return 'Cancelada'
        default:
          return '?'
      }
    },
    sendCommercialEmail(){
      this.$store.dispatch('checkoutStore/sendCommercialEmail', this.openOrder?._id);
      this.showCommercialEmail = false;
    },
    receiveOrder(formObject){
      const checkoutRedirect = process.env.VUE_APP_SHOP_BASE_URL + '/checkout'
      const myUrlWithParams = new URL(checkoutRedirect);

      for (const key in formObject) {
        if (Object.hasOwnProperty.call(formObject, key)) {
          const element = formObject[key];
          myUrlWithParams.searchParams.append(key, element);
        }
      }

      window.open(myUrlWithParams.href, '_blank');
      setTimeout(() => {  this.goToOrdersPage(); }, 5000);
    },
    async shipOrder(data){
      await this.updateOrderStatus('IN-PROGRESS',
        {
          fullfillingData: {
            store: '',
            orderNumber: '',
            totalCostPriceOfItemInUSD: '0'
          },
          shippingData: data
        }
      );
    },
    async completeOrder(data) {
      await this.updateOrderStatus('COMPLETED',
        {
          deliveryData: data
        }
      );
    },
    async updateOrderStatus(status, data) {
      try {
        const isSuccess = await this.$store.dispatch(
          'orderStore/updateOrderStatus',
          {
            orderId:this.openOrder?._id,
            status,
            ...data
          }
        );
        if (isSuccess) {
          await this.$store.dispatch('orderStore/openOrderDetail', this.openOrder);
          this.openOrder = this.order;

          this.$notify({
            group: 'all',
            type: 'success',
            text: 'Estado de la orden actualizado ! ',
          });
        } else {
          throw new Error('Error al modificar el estado de la orden');
        }
      } catch (error) {
        console.log('Error', error);
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Error al modificar el estado de la orden',
        });
      }
    },

    async deleteCheckout() {
      try {
        const isSuccess = await this.$store.dispatch(
          'checkoutStore/deleteCheckoutById', this.checkout._id
        );
        if (isSuccess) {
          this.goToOrdersPage();

          this.$notify({
            group: 'all',
            type: 'success',
            text: 'Borrador de orden eliminado ! ',
          });
        } else {
          throw new Error('Error al eliminar el borrador de orden');
        }
      } catch (error) {
        console.log('Error', error);
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Error al eliminar el borrador de orden',
        });
      }
    },

    goToOrdersPage() {
      this.$store.commit('orderStore/setOpenOrder', null);
      this.$router.push({ path: '/orders' });
    },

    goCheckoutsPage() {
      this.$store.commit('checkoutStore/setCheckoutDetail', null);
      this.$router.push({ path: '/orders/checkouts' });
    },
  },

  computed: {
    shopProductUrl(){
      return `${process.env.VUE_APP_SHOP_BASE_URL}/products/`;
    },
    ...mapGetters({
      order: 'orderStore/openOrder',
      checkout: 'checkoutStore/getOpenCheckout',
      permissions: 'authStore/permissions',
    }),

    addr() {
      return this.openOrder?.mailing_address;
    },

    payments() {
      return this.openOrder?.payment_info.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
    },

    permissionGranted() {
      if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
      return (
        this.permissions
        && this.permissions.indexOf(Permission.ORDER_MANAGE) >= 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#order-detail {
  font-size: 0.875rem;

  .order-desc {
    li {
      list-style: none;
    }
  }

  hr {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .status {
    button {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .comments {
    margin-top: 1rem;
  }
}
</style>
